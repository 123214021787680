import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";


function Reviews() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <BaseLayout PageName="Reviews">
            <div className="md:max-w-full w-full">
                <TransparentHeader
                    headertitle="Reviews"
                    Subheader="Reviews"
                    bgimg={`${rpdata?.stock?.[4]}`}
                     // para cambiar el svg del header busquemos en la carpeta css y buscamos el archivo de svgStyles
          // los estilo de svg para el header empiezan svgTranspareHeader seguido de un numero
          svgClass='svgTranspareHeader2'

                />
            </div>
            <div className="w-4/5 mx-auto py-[100px]">
          
            <review-widget widgetId="ce3c2b7d-3f60-4913-980f-ad2a6a46b45b"></review-widget>
            </div>
        </BaseLayout>
    );
}

export default Reviews;
